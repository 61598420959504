
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}
.science{
  background: #4fb365; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #4fb365 0%, #93d1a1 50%, #78c589 51%, #cae8d1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #4fb365 0%,#93d1a1 50%,#78c589 51%,#cae8d1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #4fb365 0%,#93d1a1 50%,#78c589 51%,#cae8d1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4fb365', endColorstr='#cae8d1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.ticket{
  background: #1e5799; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #1e5799 0%,#2989d8 50%,#207cca 51%,#7db9e8 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #1e5799 0%,#2989d8 50%,#207cca 51%,#7db9e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.deo{
  background: #ff7f50; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ff7f50 0%, #ffbfa7 50%, #ffb296 51%, #ffd8ca 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ff7f50 0%,#ffbfa7 50%,#ffb296 51%,#ffd8ca 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ff7f50 0%,#ffbfa7 50%,#ffb296 51%,#ffd8ca 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f50', endColorstr='#ffd8ca',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.chip{
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 24px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  font-weight: 500;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
}
.chip-100{
  background-color: #4fb365;
}
.chip-0{
  background-color: #e53835;
}
.chip-x{
  background-color: #F6BD60;
}
.chip-label{
  padding: 0 8px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color:white;
}

.step{
  background-color:#eaeaf0;
  position:relative;
  height:30px;
  margin-right:30px;
  margin-left:6px;
  display: flex;
  align-items: center;
  padding-left: 4px;
}
.step::after{
  content:"";
  position:absolute;
  top:0;
  right:-30px;
  border-style:solid;
  border-Width:15px 0 15px 30px;
  border-color:transparent transparent transparent #eaeaf0
}
.active{
  background-color: #4fb365 !important;
  color: #fff;
}
.active::after{
  border-color:transparent transparent transparent #4fb365 !important;
}
.active::before{
  border-color:#4fb365 transparent #4fb365 transparent !important;
}

.step::before{
  content:"";
  position:absolute;
  top:0;
  left:-30px;
  border-style:solid;
  border-width:15px 0 15px 30px;
  border-color:#eaeaf0 transparent #eaeaf0 transparent
}

.step_first{
  background-color:#eaeaf0;
  position:relative;
  height:30px;
  margin-right:30px;
  display: flex;
  align-items: center;
  padding-left: 4px;
}
.step_first::after{
  content:"";
  position:absolute;
  top:0;
  right:-30px;
  border-style:solid;
  border-width:15px 0 15px 30px;
  border-color:transparent transparent transparent #eaeaf0;
}

.container-errore{
  border: 1px solid #f00;
  border-radius: 5px;
  padding: 16px 8px;
  position:relative;
  margin-top: 13px;
}
.container-errore::before{
  content:"Errore";
  position:absolute;
  top:-12px;
  background-color: #F4F6F8;
  padding:2px 10px;
  left:16px;
  color:#f00;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.container-success{
  border: 1px solid rgba(39, 167, 74, 0.85);
  border-radius: 5px;
  padding: 16px 8px;
  position:relative;
  margin-top: 13px;
}

.container-success::before{
  content:"Successo";
  position:absolute;
  top:-12px;
  background-color: #F4F6F8;
  padding:2px 10px;
  left:16px;
  color:rgba(39, 167, 74, 0.85);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.bannerShadow{
  -webkit-box-shadow: 0px 0px 11px 5px #666666; 
  box-shadow: 0px 0px 11px 5px #666666;
}
