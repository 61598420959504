.heroSceneContainer{
    width: 100%;
    background-color: red;
    height: 700px;
}

.my-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* height: 100vh; */
    padding: 75;
    line-height: 1.5;
    font-size: 1.5em;
    
}
.header{
  background-color: #111;
}
.headline{
    position: relative;
    height: 700px;
    color: #fff;
    text-align: center;
    
}
.sl1-card{
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.39); 
    box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.39);
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    display: flex;
}
.sl1-card-content{
    /* width: 70%; */
    padding:16px 32px;
    text-align: left;
}
.team-container{
    display: flex;
    flex-direction: column;
}
.aboutUs-title{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 500;
}
.aboutUs-text{
    padding:8px 0px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0px;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
}
.aboutUs-text p{
    margin-bottom: 8px;
}

.divider{
    border-color: green;
    width: 200px;
    /* margin: 0 auto; */
    position: relative;
}
.divider::after{
    content:'';
    position:absolute;
    left: 50%;
    width:10;
    height: 10;
    border-radius: 50;
    background-color: red;
}
.member{
    display: flex;
    width:100%;
    padding:5px;
    margin: 5px 0;
}
.avatar{
    width:70px !important;
    height: 70px;
    margin:0 12px 0 0 
}
.nome{
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.info{
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    font-size: 16px;
    color:#666
}
.info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.analisi{
    text-align: left;
    padding:16px 32px;
    /* width: 850px; */
}
.contact-form-container{
    /* width:50%; */
    margin:16px 0;
}
.column-flex{
    display: flex;
    flex-direction: column;
}
.contact-wrap{
    padding: 16px 0;
    /* border: #ccc solid;
    border-width: 0 1px 0 0; */
}
.contact-info{
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 16px 32px;
    text-align: left;
}
.contact-list{
    list-style: none;
}
.contact-item{
    display: flex;
    align-items: center;
    margin: 8px 0; 
}
.contact-text{
    margin-left: 12px;
}
.text-footer{
    margin-left: 12px;
    color:#eee;
}
.contact-btn{
    width: 200px;
    margin-top: 32px !important;
}
.footer{
    background-color: #1b7433;
    padding:16px;
    display:flex;
    justify-content: center;
}
.footerCol{
    color:#eee
}
.soclial-list{
    list-style: none;
    display: flex;
}
.soclial-item{
    padding:8px
}
/***************************
    LANDING

*****************************/
.body{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.container{
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.39); 
    box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.39);
    width: 100%;
    max-width: 1200px;
    padding:32px 0 0 0;
    display: flex;
}
.layer{
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.footer-last{
    color:#fff;
    font-family: 'Poppins',sans-serif;
    font-size: 12px;
    text-align: center;
    padding:"8px"
}