.nav{
    position: fixed;
    width:100%;
    z-index: 8;
    color:#fff;
    display: flex;
    height: 70px;
    
}
.toolbar{
    display:flex;
    width: 100%;
    justify-content: space-between;
    padding: 4px 32px;
    align-items: center;
}
.topBar-divider{
    background-color: #fff !important;
}
.logo{
    height: 45px;
}